@use "../../styles/" as *;

.snackbar {
  position: fixed;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2em;
  border-radius: 1em;
  color: #444;
  padding: 0.5em 1em;

  &.error {
    border: 2px solid mod-error(0, 0, -20%);
    background-color: $error-color;
  }
  &.success {
    border: 2px solid mod-success(0, 0, -20%);
    background-color: $success-color;
  }
}
