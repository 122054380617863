$my-yellow: #ffab40;

$blue-hue: 227;
$blue-sat: 57%;
$blue-lit: 82%;
$my-sky-blue: hsl($blue-hue, $blue-sat, $blue-lit);

@function mod-blue($hue: 0, $sat: 0, $lit: 0, $opacity: 100%) {
  @return hsl($blue-hue + $hue, $blue-sat + $sat, $blue-lit + $lit, $opacity);
}

$my-dark-blue: #252b42;

$green-hue: 76;
$green-sat: 24%;
$green-lit: 29%;
$my-green: hsl($green-hue, $green-sat, $green-lit);

@function mod-green($hue: 0, $sat: 0, $lit: 0, $opacity: 100%) {
  @return hsl(
    $green-hue + $hue,
    $green-sat + $sat,
    $green-lit + $lit,
    $opacity
  );
}

$my-rust: #a04725;

$bg-hue: 216;
$bg-sat: 13%;
$bg-lit: 15%;
$bg-color: hsl($bg-hue, $bg-sat, $bg-lit);
@function mod-bg($hue: 0, $sat: 0, $lit: 0, $opacity: 100%) {
  @return hsl($bg-hue + $hue, $bg-sat + $sat, $bg-lit + $lit, $opacity);
}
/* Putting the last f makes the work with transitions that include transparency */

$default-text: #ffff;
$opposite-text: #000;
$light-grey: #ddd;
$mid-grey: #aaa;
$low-grey: #777;

$modal-color: mod-bg(0, 0, 0, 0.7);
$notify-color: #d00;

$bg-hue: 216;
$bg-sat: 13%;
$bg-lit: 15%;
$bg-color: hsl($bg-hue, $bg-sat, $bg-lit);
@function mod-bg($hue: 0, $sat: 0, $lit: 0, $opacity: 100%) {
  @return hsl($bg-hue + $hue, $bg-sat + $sat, $bg-lit + $lit, $opacity);
}

$error-hue: 0;
$error-sat: 67%;
$error-lit: 80%;
$error-color: hsl($error-hue, $error-sat, $error-lit);
@function mod-error($hue: 0, $sat: 0, $lit: 0, $opacity: 100%) {
  @return hsl(
    $error-hue + $hue,
    $error-sat + $sat,
    $error-lit + $lit,
    $opacity
  );
}

$success-hue: 77;
$success-sat: 46%;
$success-lit: 65%;
$success-color: hsl($success-hue, $success-sat, $success-lit);
@function mod-success($hue: 0, $sat: 0, $lit: 0, $opacity: 100%) {
  @return hsl(
    $success-hue + $hue,
    $success-sat + $sat,
    $success-lit + $lit,
    $opacity
  );
}
