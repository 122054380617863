@use "../styles/" as *;
@use "../Whiteboard/KonvaWrapper/KonvaWrapper.module.scss" as *;

.Lobby {
  h1,
  h2 {
    margin: 1em 0 0.4em;
  }

  @include responsive-section();
  &.login {
    // Override to be a smaller form-oriented container width
    @include responsive-section(50ch);
  }
}

@media only screen and (max-width: 700px) {
  .Lobby {
    h1 {
      font-size: 1.6rem;
    }
  }
}
