@use "../../../styles/" as *;

.InviteOverlay {
  @include modal-cover;
}

.overlay-content {
  @include flex-center(column);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $bg-color;
  border-radius: 0.8em;
  padding: 1em;
  /* padding-right: 2.5em; */
  border: 1px solid $mid-grey;
  position: relative;

  & > * {
    margin: 0.5em;
  }

  h2 {
    margin: 0em 2em 0.5em;
    text-align: center;
  }

  button.close {
    @include reset-button;
    @include circle(1.5em);
    @include flex-center;
    position: absolute;
    top: 0.3em;
    right: 0.3em;
    border: 1px solid $default-text;
    background-color: #0000;
    padding: 0.2em;
  }
  .link-section {
    margin: 0 auto;
    max-width: 100%;
    .invite-link {
      white-space: nowrap;
      width: 60ch;
      max-width: 100%;
      overflow: auto;
      display: inline-block;
    }
  }

  .invite-form {
  }
}

.invite {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  position: relative;

  button.send {
    @include my-button;
    margin: 0 0 0 1em;
    padding: 0.7em 1em;
  }

  label {
    width: 8em;
    margin-bottom: 0.8em;
  }

  input {
    @include my-input;
  }
  &.notify {
    margin-bottom: 1.5em;
    /* need this so the after element stays inside */
    & > div {
      @include after-effect;
      &:after {
        content: attr(data-notify);
        top: 2.8em;
        left: 0;
      }
    }
  }

  &.err {
    input {
      border-color: $error-color;
    }
    & > div:after {
      color: $error-color;
    }
  }

  &.suc > div:after {
    color: mod-green(0, 50, 20);
  }
}
