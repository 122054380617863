@use "../../styles/" as *;

.Toolbar {
  margin-left: 2em;
  display: flex;
  flex-direction: column;
  width: 13em;

  h2 {
    margin: 0.2em 0;
  }
  .tool-list {
    display: flex;
    flex-direction: column;

    button {
      @include reset-button;
      @include flex-center;
      justify-content: flex-start;
      font-weight: bolder;
      height: 2em;
      padding: 0.2em 0.3em;
      margin: 0.2em 0em;
      background-color: mod-bg(0, 0, 10);
      border: 1px solid #0000;

      .tool-indicator {
        @include square(1.5em);
        border-radius: 0.3em;
        &.color {
          border: 1px solid $mid-grey;
        }
      }

      &.selected,
      &:active {
        background-color: mod-bg(0, 0, 20);
        border: 1px solid $mid-grey;
      }
      span {
        margin-left: 0.5em;
      }
    }
  }

  .user-list {
    margin-top: 1em;
    .user-name {
      margin: 0.3em 0;
    }
  }
  // this shows up in user list little too
  button.invite {
    @include my-button;
    margin: 0.5em 0;
    font-weight: bolder;
  }
}

.user-list-little {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .Toolbar {
    --button-size: min(2rem, calc(calc(100vw - 8rem) / 7));
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: clamp(0px, 100vw, 800px);
    box-sizing: border-box;
    padding: 0 0.8em;
    margin: 0 0 0.3em;
    .tool-list {
      flex-direction: row;
      button {
        margin: 0.2em 0.2em;
        padding: 0.3em 0.3em;
        @include square(var(--button-size));
        .tool-indicator {
          @include square(100%);
        }
        span {
          display: none;
        }
      }
    }
    .user-list {
      display: none;
    }
    .spacer {
      display: none;
    }
    .user-list-little {
      // forces flex box not to shrink this
      flex-shrink: 0;
      width: var(--button-size);
      height: 100%;
      display: block;
      position: relative;
      border-radius: 0.2em;
      border: 1px solid #0000;

      padding: 0 0.1em;
    }
    .user-list-little [class*="Icon"] {
      height: 100%;
      width: 100%;
    }
    .user-list-little .user-count {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $notify-color;
      font-size: 0.8rem;
      height: 1rem;
      width: 1rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .user-list-little .full-list {
      display: none;
      position: absolute;
      background-color: $bg-color;
      z-index: 10;
      padding: 0.2em 0.8em 0.5em 0.5em;
      border-radius: inherit;
      right: 0;
      border: 1px solid $light-grey;
    }
    .user-list-little .full-list * {
      white-space: nowrap;
    }
    .user-list-little .full-list h3 {
      font-size: 1rem;
      margin: 0.2em 0;
    }
    .user-list-little:focus-within {
      border: 1px solid var(--light-grey);
    }
    .user-list-little:focus-within .full-list {
      display: block;
    }
  }
}
