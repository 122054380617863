@use "../../styles/" as *;

@mixin form-field-container {
  background-color: mod-bg(0, 0, 10);
  border-radius: 0.3em;
  padding: 0.5em 1em;
  position: relative;

  label {
    margin: 0.3em 0;
    margin-right: 1em;
    display: block;
  }

  input {
    @include reset-input;
    display: block;
    width: 90%;
    margin: 0.3em 1em 0.3em 0;
    border-bottom: 1px solid $mid-grey;
    &:focus-visible {
      outline: none;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }
}

.myForm {
  button {
    @include my-button;
    padding: 0.8em 1em;
    margin: 0.5em 0;
  }

  // & > * {
  //   margin-bottom: 1em;
  // }

  .submitMessageHandler {
    &::after {
      display: block;
      content: attr(data-error);
    }
    &.error {
      &::after {
        margin-top: 0em;
        color: $error-color;
      }
    }
    &.success {
      &::after {
        color: mod-green(0, 10, 30);
      }
    }
  }

  .fieldRow {
    @include form-field-container;
    margin-bottom: 1em;
    width: 100%;
    // makes the width 100% work the way I want it to
    box-sizing: border-box;
    .pw-toggle {
      // @include reset-button;
      width: min-content;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
    }

    &.error {
      background-color: mod-bg(80, 0, 15);
      &::after {
        content: attr(data-error);
        color: $error-color;
        margin: 0.3em 0;
        // grid-column: span 2;
      }
    }
  }
}
