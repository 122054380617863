@use "../styles/" as *;
@use "../_Utilities/MyForm/MyForm.module.scss" as *;

.logout {
  text-align: right;
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  button {
    @include my-button;
    margin: 0.4em 0;
    border-radius: 0.3em 0 0 0.3em;
    border: 2px solid $my-sky-blue;
    &.alt {
      border-radius: 0 0.3em 0.3em 0;
      padding: 0.4em;
    }
  }
}
.modal {
  @include modal-cover;
  .modal-body {
    @include modal-body;
    padding: 1.5em;
    max-width: min(80ch, 80%);

    header {
      padding-right: 2em;
      position: relative;
      margin: 0 0 1.5rem;
      h1 {
        margin: 0;
      }
      button {
        @include my-close-button;
      }
    }

    form {
      display: flex;
      flex-wrap: wrap;
      align-items: space-between;
      justify-content: space-between;
    }

    .alt {
      @include alt-button;
    }

    .email-line {
      @include form-field-container;
      margin: 0.5em 0;
    }
  }
}
