@use "../../styles/" as *;
// Just imports the whiteboard colors
@use "../../Whiteboard/KonvaWrapper/KonvaWrapper.module.scss" as *;

.previews {
  /* --button-margin: 0.3em; */
  --grid-gap: 1em;
  --col-width: min(15em, calc(50% - 0.5em));
  width: min(80em, 100%);
  margin: 1em auto;
  /* padding: 0 1em; */
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--col-width));
  justify-content: center;
  gap: var(--grid-gap);

  .loading {
    @include h-font;
    grid-column: 1 /-1;
    text-align: center;
    padding: 3em;
  }

  header {
    // Makes this element span the full grid row
    grid-column: 1 /-1;
    display: flex;

    .titles {
      flex: 1;
    }
    h2 {
      margin-top: 0.5em;
    }
    .logout {
      margin-top: 1.8rem;
    }
  }

  .create-new {
    flex: 1;
    @include flex-center;
    font-size: 3rem;
    padding-bottom: 0.5em;
    color: mod-bg(0, 0, 60);
    font-weight: bolder;
  }

  // This needs to be applied when it's in the modal too
  .preview-stage {
    @include stage-colors;
  }

  .preview-box {
    // @include reset-button;
    @include flex-center(column);
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0.5em;
    background-color: mod-bg(0, 0, 10);
    border-radius: 0.3em;
    /* give it an invisible border so it doesn't shift around when hovered */
    border: 1px solid #0000;
    position: relative;

    &:hover,
    &:focus-visible {
      background-color: mod-bg(0, 0, 20);
      border: 1px solid $mid-grey;
    }

    .delete {
      @include my-close-button(0.2em, 0.2em);
      z-index: 11;
    }

    .click-cover {
      @include reset-button;
      // Because the stage has a positioned canvas that ends up overlapping
      z-index: 10;
      background-color: transparent;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    h3 {
      text-align: center;
      white-space: nowrap;
      // If it's got a long name, I'll just be hidden, you need a bigger screen..
      // I guess I could have a fade out effect...
      // I switched away from scrolling because I'm covering everything up with a clear button
      overflow: hidden;
      max-width: 100%;
      width: 100%;
      margin-top: 0.2em;
    }
    .users {
      margin: 0.3em;
    }
  }
}

.delete-modal {
  @include modal-cover;
  .delete-body {
    @include modal-body;
    @include flex-center(column);
    h2 {
      margin: 0;
    }
    .stage-container {
      margin: 1em 0;
    }
    .button-row {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    button {
      @include my-button;
      margin: 0.5em;
      &.alt {
        @include alt-button;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .previews {
    --grid-gap: 0.5em;
    header {
      h2 {
        font-size: 1.3em;
      }
    }
  }
}
