@use "../styles/" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: hidden;

  button {
    @include reset-button;
    padding: 0.5em;
    border-radius: 0.3em;
    margin: 0.5em;
    text-align: center;
  }

  input.board-name {
    @include reset-input;
  }
  input.board-name,
  .board-name {
    font-size: 2em;
    color: $my-yellow;
    border-bottom: 1px solid #0000;
    text-align: center;
    max-width: 10em;
    margin: 0.3em 0;
  }

  input.board-name:focus-visible {
    outline: none;
    border-bottom: 1px solid $my-yellow;
  }

  .body {
    display: flex;
    width: 100vw;

    justify-content: space-between;
    .big-home-button,
    .little-home-button {
      @include my-button;
      text-decoration: none;
    }
    .big-home-button {
      position: fixed;
      top: 0.5em;
      left: 1.5em;
    }
    .little-home-button {
      display: none;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    .body {
      @include flex-center(column);
      .big-home-button {
        display: none;
      }
      .little-home-button {
        display: block;
        position: fixed;
        bottom: 0.5em;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .DrawWithFriends h1 {
      margin: 0.2em 0;
    }
  }
}
