@use "../../styles/" as *;

.loginSection {
  button {
    @include my-button;
  }

  form {
    // max-width: 40ch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .fancy-logins {
    margin: 1em 0;
    .modal {
      @include modal-cover;
      .modal-body {
        @include modal-body;
        button {
          margin: 0.5em;
        }
      }
    }
  }
}
