@use "../../styles/" as *;

@mixin stage-colors {
  // Defines the color, and the border behavior
  border: 0.2em solid $mid-grey;
  background-color: #efefef;
  box-sizing: border-box;
}

.canvas-wrapper {
  @include flex-center;
  flex: 1;
  margin: 1em;
  overflow: show;

  .canvas-resizer {
    position: relative;
    transform-origin: top center;
    .canvas-stage {
      @include stage-colors;
      top: 0;
      left: 0;
      position: absolute;
    }

    .user-indicator,
    .eraser-cursor {
      position: absolute;
      z-index: 10;
      /* Because the whiteboard is white, these need to be these colors... */
      background-color: #333;
      color: white;
    }

    .eraser-cursor {
      background-color: #0000;
      border-radius: 100%;
      border: 1px solid black;
      pointer-events: none;
    }
  }
}
