@use "./mixins.scss" as *;
@use "./colors.scss" as *;
@use "./fonts.scss" as *;

// Libre Baskerville
// Roboto Slab (light and regular) because regular is basically bold
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto+Slab:wght@300;400&display=swap");
@import "./normalize.css";

html {
  font-size: 16px;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
body {
  font-size: 16px;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

body {
  @include custom-scrollbar;
  @include b-font;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
}

a:focus-visible,
button:focus-visible {
  outline: 2px solid $default-text;
  outline-offset: 1px;
}

::selection {
  background-color: mod-bg(0, 0, 30%);
  color: $default-text;
}

.no-wrap {
  white-space: nowrap;
}

:is(h1, h2, h3, h4) {
  @include h-font;
  margin: 0;
}
