@use "./colors.scss" as *;

$b-font: "Roboto Slab", serif;
$h-font: "Libre Baskerville", serif;

@mixin h-font {
  font-family: $h-font;
  color: $my-yellow;
  font-weight: normal;
}

@mixin b-font {
  color: $default-text;
  font-family: $b-font;
  font-weight: 300;
}
