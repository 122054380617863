@use "./colors.scss" as *;

@mixin home-section-defaults {
  margin-top: 20vw;
  margin-bottom: 20vw;
  header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
    svg {
      height: 5.3em;
      width: 12em;
      margin-right: -7em;
      margin-top: -0.5em;
    }
    h1 {
      font-size: 2.5rem;
      width: min-content;
      white-space: nowrap;
      padding-right: 0.5em;
      padding-left: 0.3em;
      @include after-underline();
      &::after {
        height: 2px;
        bottom: -4px;
      }
    }
  }
}

@mixin responsive-section($max-width: 100ch, $min-width: 93vw) {
  width: min($max-width, $min-width);
  margin-left: auto;
  margin-right: auto;
}

@mixin flex-center($direction: row) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: $direction;
}

@mixin square($size) {
  height: $size;
  width: $size;
}
@mixin circle($size) {
  @include square($size);
  border-radius: $size;
}

@mixin full-screen {
  height: 100vh;
  width: 100vw;
}

@mixin reset-link {
  color: inherit;
  text-decoration: none;
}

@mixin reset-button($display: inline) {
  background: unset;
  border: none;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
  display: $display;
  text-align: left;
  box-sizing: unset;
  @if ($display == block) {
    width: 100%;
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-input {
  background: unset;
  border: none;
  color: inherit;
}

@mixin alt-button {
  background-color: $bg-color;
  border: 2px solid $my-sky-blue;
  color: $my-sky-blue;
  &:active {
    background-color: mod-bg(0, 0, 5%);
  }
}

@mixin my-button {
  @include reset-button;
  color: $opposite-text;
  background-color: $my-sky-blue;
  padding: 0.8em 1em;
  font-weight: bolder;
  cursor: pointer;
  border-radius: 0.3em;
  text-align: center;
  &.alt {
    @include alt-button();
  }
  &:active {
    background-color: mod-blue(0, 0, -5%);
  }
  @media screen and (max-width: 800px) {
    padding: 0.5em 0.8em;
  }
}

@mixin my-close-button($top: 0, $right: 0) {
  @include reset-button;
  @include circle(1.5em);
  @include flex-center;
  position: absolute;
  top: $top;
  right: $right;
  color: #fff;
  border: 2px solid $mid-grey;
  &:active {
    background-color: mod-bg(0, 0, 10%);
  }
}

@mixin my-input {
  @include reset-input;
  background-color: mod-bg(0, 0, 10);
  border-bottom: 1px solid $mid-grey;
  padding: 0.5em 0.8em;
  border-radius: 0.3em 0.3em 0 0;
  &:focus {
    background-color: mod-bg(0, 0, 20);
  }
}

@mixin modal-cover {
  @include flex-center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: $modal-color;
  z-index: 102;
  backdrop-filter: blur(2px);
}

// Not putting this in the modal cover because I might want to style the body
// differently...
@mixin modal-body {
  max-width: min(80%, 80ch);
  background-color: $bg-color;
  border: 1px solid $mid-grey;
  border-radius: 0.5em;
  padding: 0.8em;
}

@mixin custom-scrollbar {
  $thumb-color: #aaa;
  $scroll-bar-color: #0000;
  $scroll-bar-width: 0.8em;

  margin: 0;
  padding: 0;
  /* For Firefox */
  scrollbar-width: $scroll-bar-width;
  scrollbar-color: $thumb-color $scroll-bar-color;

  // Firefox uses different syntax
  &::-webkit-scrollbar {
    background-color: $scroll-bar-color;
    width: $scroll-bar-width;
  }

  &::-webkit-scrollbar-track {
    background-color: $scroll-bar-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    box-sizing: border-box;
    border-radius: 0.3em;
    width: 0.8em;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
}

@mixin after-effect {
  position: relative;
  &::after {
    content: "";
    position: absolute;
  }
}

@mixin after-underline {
  @include after-effect;
  &::after {
    width: 100%;
    height: 1px;
    background-color: currentColor;
    bottom: -2px;
    left: 0;
  }
}
